"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
var _users = _interopRequireDefault(require("@/api/users"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      var usernamereg = /^[a-z0-9]+$/i;
      if (!value) {
        callback(new Error('请输入用户名'));
      } else if (value.length < 6) {
        callback(new Error("请输入6-32位"));
      } else if (!usernamereg.test(value)) {
        callback(new Error("用户名必须使用字母、数字、或字母和数字组合"));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      var passwordreg = /^(((?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[0-9])(?=.*[^\s0-9a-zA-Z])|(?=.*[a-zA-Z])(?=.*[^\s0-9a-zA-Z]))[^\s]+)$/;
      if (value.length < 8) {
        callback(new Error('请输入8-16位'));
      } else if (!passwordreg.test(value)) {
        callback(new Error('密码必须包含数字、字母、特殊字符中至少两种'));
      } else {
        callback();
      }
    };
    return {
      title: localStorage.getItem('title') || '',
      form: {
        dealerCode: '',
        account: '',
        password: ''
      },
      rules: {
        dealerCode: [{
          required: true,
          message: '请输入经销商编码',
          trigger: 'blur'
        }],
        account: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      loading: false
    };
  },
  methods: {
    back: function back() {
      this.$router.back();
    },
    save: function save() {
      var _this = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          _users.default.accountApplication(_this.form).then(function (res) {
            _this.loading = false;
            if (res.code === 200) {
              _this.$message.success('注册成功，请登录');
              _this.back();
            }
          }).catch(function (err) {
            _this.loading = false;
          });
        }
      });
    }
  }
};