"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _getUrl = _interopRequireDefault(require("@/utils/get-url"));
/**
 * 消息平台
 */

var objApi = {
  // ******************************消息类型配置******************************
  /**
   * 分页查询消息类型
   * @param {*} data
   */
  getMessageTypeList: function getMessageTypeList(data) {
    return _request.default.get((0, _getUrl.default)("/messageType/search", 8), {
      params: data
    });
  },
  /**
   * 不分页查询消息类型
   * @param {*} data
   */
  getNoPageMessageTypeList: function getNoPageMessageTypeList(data) {
    return _request.default.get((0, _getUrl.default)("/messageType/list", 8), {
      params: data
    });
  },
  /**
   * 创建消息类型
   * @param {*} data
   */
  createMessageType: function createMessageType(data) {
    var headers = {
      showLoading: true
    };
    return _request.default.post((0, _getUrl.default)("/messageType/create", 8), data, {
      headers: headers
    });
  },
  /**
   * 更新消息类型
   * @param {*} messageTypeId
   * @param {*} data
   */
  updateMessageType: function updateMessageType(messageTypeId, data) {
    var headers = {
      showLoading: true
    };
    return _request.default.post((0, _getUrl.default)("/messageType/update?messageTypeId=".concat(messageTypeId), 8), data, {
      headers: headers
    });
  },
  /**
   * 查询一条消息类型
   * @param {*} messageTypeId
   */
  getMessageTypeInfo: function getMessageTypeInfo(messageTypeId) {
    return _request.default.get((0, _getUrl.default)("/messageType/get?messageTypeId=".concat(messageTypeId), 8));
  },
  /**
   * 批量删除消息类型
   * @param {*} messageTypeIds
   */
  deleteMessageType: function deleteMessageType(messageTypeIds) {
    return _request.default.delete((0, _getUrl.default)("/messageType/batchDelete/".concat(messageTypeIds), 8));
  },
  // ******************************消息通知渠道配置******************************
  /**
   * 分页查询消息通知渠道
   * @param {*} data
   */
  getMessageNotifyChannelList: function getMessageNotifyChannelList(data) {
    return _request.default.get((0, _getUrl.default)("/messageNotifyChannel/search", 8), {
      params: data
    });
  },
  /**
   * 不分页查询消息通知渠道
   * @param {*} data
   */
  getNoPageMessageNotifyChannelList: function getNoPageMessageNotifyChannelList(data) {
    return _request.default.get((0, _getUrl.default)("/messageNotifyChannel/list", 8), {
      params: data
    });
  },
  /**
   * 创建消息通知渠道
   * @param {*} data
   */
  createMessageNotifyChannel: function createMessageNotifyChannel(data) {
    var headers = {
      showLoading: true
    };
    return _request.default.post((0, _getUrl.default)("/messageNotifyChannel/create", 8), data, {
      headers: headers
    });
  },
  /**
   * 更新消息通知渠道
   * @param {*} messageNotifyChannelId
   * @param {*} data
   */
  updateMessageNotifyChannel: function updateMessageNotifyChannel(messageNotifyChannelId, data) {
    var headers = {
      showLoading: true
    };
    return _request.default.post((0, _getUrl.default)("/messageNotifyChannel/update?messageNotifyChannelId=".concat(messageNotifyChannelId), 8), data, {
      headers: headers
    });
  },
  /**
   * 查询一条消息通知渠道
   * @param {*} messageNotifyChannelId
   */
  getMessageNotifyChannelInfo: function getMessageNotifyChannelInfo(messageNotifyChannelId) {
    return _request.default.get((0, _getUrl.default)("/messageNotifyChannel/get?messageNotifyChannelId=".concat(messageNotifyChannelId), 8));
  },
  /**
  * 批量删除消息类型
  * @param {*} messageNotifyChannelId
  */
  deleteMessageNotifyChannel: function deleteMessageNotifyChannel(messageNotifyChannelId) {
    return _request.default.delete((0, _getUrl.default)("/messageNotifyChannel/batchDelete/".concat(messageNotifyChannelId), 8));
  },
  /**
  * 获取通知方式配置
  * @param {*} data
  */
  getConfigSchema: function getConfigSchema(data) {
    return _request.default.get((0, _getUrl.default)("/messageNotifyChannel/getConfigSchema", 8), {
      params: data
    });
  },
  /**
  * 启用/禁用通知渠道
  * @param {*} messageNotifyChannelId
  */
  enableDisableConfigSchema: function enableDisableConfigSchema(messageNotifyChannelId) {
    return _request.default.put((0, _getUrl.default)("/messageNotifyChannel/enabled?messageNotifyChannelId=".concat(messageNotifyChannelId), 8));
  },
  // ******************************消息模板管理******************************
  /**
   * 分页查询消息模板
   * @param {*} data
   */
  getMessageTemplateList: function getMessageTemplateList(data) {
    return _request.default.get((0, _getUrl.default)("/messageTemplate/search", 8), {
      params: data
    });
  },
  /**
  * 新增消息模板
  * @param {*} data
  */
  createMessageTemplate: function createMessageTemplate(data) {
    var headers = {
      showLoading: true
    };
    return _request.default.post((0, _getUrl.default)("/messageTemplate/create", 8), data, {
      headers: headers
    });
  },
  /**
  * 更新消息模板
  * @param {*} templateId
  * @param {*} data
  */
  updateMessageTemplate: function updateMessageTemplate(templateId, data) {
    var headers = {
      showLoading: true
    };
    return _request.default.post((0, _getUrl.default)("/messageTemplate/update?templateId=".concat(templateId), 8), data, {
      headers: headers
    });
  },
  /**
   * 查询一条消息模板
   * @param {*} templateId
   */
  getMessageTemplateInfo: function getMessageTemplateInfo(templateId) {
    return _request.default.get((0, _getUrl.default)("/messageTemplate/get?templateId=".concat(templateId), 8));
  },
  /**
  * 批量删除消息模板
  * @param {*} templateIds
  */
  deleteMessageTemplate: function deleteMessageTemplate(templateIds) {
    return _request.default.delete((0, _getUrl.default)("/messageTemplate/batchDelete/".concat(templateIds), 8));
  },
  /**
  * 获取消息类型参数
  * @param {*} data
  */
  getSchema: function getSchema(data) {
    return _request.default.get((0, _getUrl.default)("/messageType/getSchema", 8), {
      params: data
    });
  },
  /**
  * 获取消息模板
  * @param {*} data
  */
  getTemplateSchema: function getTemplateSchema(data) {
    return _request.default.get((0, _getUrl.default)("/messageNotifyChannel/getTemplateSchema", 8), {
      params: data
    });
  },
  // ******************************消息表******************************
  /**
   * 分页查询消息
   * @param {*} data
   */
  getMessageList: function getMessageList(data) {
    return _request.default.get((0, _getUrl.default)("/message/search", 8), {
      params: data
    });
  },
  /**
   * 根据主键查询消息
   * @param {*} messageId
   */
  getMessageInfo: function getMessageInfo(messageId) {
    return _request.default.get((0, _getUrl.default)("/message/get?messageId=".concat(messageId), 8));
  },
  /**
   * 读取消息
   * @param {*} messageId
   */
  readMessage: function readMessage(messageId) {
    return _request.default.post((0, _getUrl.default)("/message/read?messageId=".concat(messageId), 8));
  },
  /**
  * 查询工作台消息
  * @param {*} data
  */
  getUserMessageList: function getUserMessageList(data) {
    return _request.default.get((0, _getUrl.default)("/message/getUserMessageList", 8), {
      params: data
    });
  }
};
var _default = exports.default = objApi;