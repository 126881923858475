"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _getUrl = _interopRequireDefault(require("@/utils/get-url"));
/**
 * 顶部菜单接口
 */

var obj = {
  /**
   * 列表
   * @param {*} data
   */
  getList: function getList(data) {
    return _request.default.get((0, _getUrl.default)("/portalTopMenu/search", 1), {
      params: data
    });
  },
  /**
   * 新增顶部菜单
   * @param {*} data 参数
   */
  createTopMenu: function createTopMenu(data) {
    return _request.default.post((0, _getUrl.default)("/portalTopMenu", 1), data);
  },
  /**
   * 查询顶部菜单
   * @param {*} id
   */
  getTopMenuByID: function getTopMenuByID(id) {
    return _request.default.get((0, _getUrl.default)("/portalTopMenu/getPortalTopMenu?topMenuId=".concat(id), 1));
  },
  /**
   * 删除版本
   * @param {*} id 当前功能菜单的id
   */
  deleteTopMenu: function deleteTopMenu(id) {
    return _request.default.delete((0, _getUrl.default)("/portalTopMenu/".concat(id), 1));
  },
  /**
   * 更新功能菜单
   */
  updateTopMenu: function updateTopMenu(id, data) {
    return _request.default.put((0, _getUrl.default)("/portalTopMenu/".concat(id), 1), data);
  },
  /**
  * 根据门户id查询菜单树
  */
  getTopMenuTreeByPortalId: function getTopMenuTreeByPortalId(id) {
    return _request.default.get((0, _getUrl.default)("/portalTopMenu/getPortalTopMenuTree?portalId=".concat(id), 1));
  }
};
var _default = exports.default = obj;