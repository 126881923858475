"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  data: function data() {
    return {
      themeColor: ''
    };
  },
  methods: {
    setActiveMenuBg: function setActiveMenuBg() {
      if (localStorage.getItem('themeColor')) {
        this.themeColor = localStorage.getItem('themeColor');
        var doc = document.getElementsByClassName("el-menu-item");
        if (!_.isEmpty(doc) && this.themeColor) {
          for (var i = 0; i < doc.length; i++) {
            var element = doc[i];
            if (element._prevClass == 'el-menu-item is-active') {
              // element.style.backgroundColor = this.themeColor;
              element.style.color = this.themeColor;
            } else {
              // element.style.backgroundColor = variables.menuBg;
              element.style.color = '#333333';
            }
          }
          this.$forceUpdate();
        }
      }
    }
  },
  mounted: function mounted() {
    this.setActiveMenuBg();
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["sidebar"])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    },
    routes: function routes() {
      return this.$router.options.routes;
    }
  }),
  watch: {
    $route: function $route(route) {
      var _this = this;
      this.$nextTick(function () {
        _this.setActiveMenuBg();
      });
    }
  }
};