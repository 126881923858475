"use strict";

var _interopRequireWildcard = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
var _auth = require("@/utils/auth");
var _router = _interopRequireWildcard(require("@/router"));
var state = {
  token: (0, _auth.getToken)(),
  name: '',
  avatar: '',
  introduction: '',
  roles: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_INTRODUCTION: function SET_INTRODUCTION(state, introduction) {
    state.introduction = introduction;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  }
};
var actions = {
  // user login
  login: function login(_ref, data) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', data.accessToken);
      (0, _auth.setToken)(data.accessToken);
      resolve();
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      var data = {
        roles: ['admin'],
        introduction: 'I am a super administrator',
        avatar: '',
        name: 'Super Admin'
      };
      if (!data) {
        reject('Verification failed, please Login again.');
      }
      var roles = data.roles,
        name = data.name,
        avatar = data.avatar,
        introduction = data.introduction;

      // roles must be a non-empty array
      if (!roles || roles.length <= 0) {
        reject('getInfo: roles must be a non-null array!');
      }
      commit('SET_ROLES', roles);
      commit('SET_NAME', name);
      commit('SET_AVATAR', avatar);
      commit('SET_INTRODUCTION', introduction);
      resolve(data);
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state,
      dispatch = _ref3.dispatch;
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      localStorage.clear();
      sessionStorage.clear();
      (0, _auth.removeToken)();

      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, {
        root: true
      });
      resolve();
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      (0, _auth.removeToken)();
      resolve();
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};