"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _admin = _interopRequireDefault(require("./admin"));
var _editor = _interopRequireDefault(require("./editor"));
//
//
//
//
//
//
var _default = exports.default = {
  name: "Dashboard",
  components: {
    adminDashboard: _admin.default,
    editorDashboard: _editor.default
  },
  data: function data() {
    return {
      currentRole: "adminDashboard"
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["roles"])),
  created: function created() {
    if (!this.roles.includes("admin")) {
      this.currentRole = "adminDashboard";
    }
  }
};