"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var _getUrl = _interopRequireDefault(require("@/utils/get-url"));
/**
 * 用户管理接口
 */

var obj = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示数量
   * @param {*} data 参数
   */
  getList: function getList(data) {
    return _request.default.get((0, _getUrl.default)("/users", 1), {
      params: data
    });
  },
  /**
   * 新增用户
   * @param {*} data 参数
   */
  createUser: function createUser(data) {
    return _request.default.post((0, _getUrl.default)("/users", 1), data);
  },
  /**
   * 查询用户
   * @param {*} id 当前用户的id
   */
  getUserByID: function getUserByID(id) {
    return _request.default.get((0, _getUrl.default)("/users/".concat(id), 1));
  },
  /**
   * 更新应用
   * @param {*} data 参数
   * @param {*} id 当前用户的id
   */
  updateUsers: function updateUsers(id, data) {
    return _request.default.put((0, _getUrl.default)("/users/".concat(id)), data);
  },
  /**
   * 重置密码
   * @param {*} id 当前用户的id
   */
  resetPassword: function resetPassword(id) {
    return _request.default.delete((0, _getUrl.default)("/users/".concat(id, "/password"), 1));
  },
  /**
   * 删除用户
   * @param {*} id 当前用户的id
   */
  deleteUser: function deleteUser(id) {
    return _request.default.delete((0, _getUrl.default)("/users/".concat(id), 1));
  },
  /**
   * 修改密码
   * @param {*} data 参数
   * @param {*} id 当前用户的id
   */
  changePassword: function changePassword(id, data) {
    return _request.default.put((0, _getUrl.default)("/users/".concat(id, "/password")), data);
  },
  /**
   *
   * 获取token
   */
  getToken: function getToken(data) {
    return _request.default.post((0, _getUrl.default)("/token", 2), data);
  },
  /**
   *
   * 刷新Token
   */
  refreshToken: function refreshToken() {
    return _request.default.post((0, _getUrl.default)("/token/refresh", 2));
  },
  /**
   *
   * 获取组织机构类型
   */
  getOrganizationType: function getOrganizationType(hostname) {
    return _request.default.get((0, _getUrl.default)("/org/manage/get/type?domainName=".concat(hostname)));
  },
  /**
   *
   * 获取验证码图片
   */
  getValidateImg: function getValidateImg() {
    return _request.default.post((0, _getUrl.default)("/captcha/getImage", 2));
  },
  /**
   * 根据token获取用户信息（经纪端）
   */
  getUserInfo: function getUserInfo() {
    return _request.default.get((0, _getUrl.default)("/employee/me", 3));
  },
  /**
   * 获取当前用户的角色菜单
   */
  getUserRoleMenus: function getUserRoleMenus() {
    return _request.default.get((0, _getUrl.default)("/user/role/getUserRoleMenus/me"));
  },
  /**
   * 根据token获取用户信息（主机厂）
   */
  getOemUserInfo: function getOemUserInfo() {
    return _request.default.get((0, _getUrl.default)("/oem/info/me", 3));
  },
  /**
   * 根据token获取用户信息（经销商）
   */
  getDealerUserInfo: function getDealerUserInfo() {
    return _request.default.get((0, _getUrl.default)("/dealer/info/me", 3));
  },
  /**
   *
   * 经销商账号申请
   * @param {*} data 内容
   */
  accountApplication: function accountApplication(data) {
    return _request.default.post((0, _getUrl.default)("/apply/account", 4), data);
  },
  /**
   * 修改密码（图形验证码）
   * @param {*}
   */
  changePasswordByCode: function changePasswordByCode(data) {
    return _request.default.post((0, _getUrl.default)("/users/changePassword?account=".concat(data.account), 2), data);
  },
  /**
   * 根据token获取用户信息
   */
  getLoginUserInfo: function getLoginUserInfo() {
    return _request.default.get((0, _getUrl.default)("/common/getLoginUser", 3));
  },
  /**
   * 获取用户帐户状态
   */
  getAccountStatus: function getAccountStatus() {
    return _request.default.get((0, _getUrl.default)("/users/getAccountStatus", 1));
  },
  /**
   * 获取绑定的用户
   */
  getBingUsers: function getBingUsers() {
    return _request.default.get((0, _getUrl.default)("/users/getMyBindings", 1));
    // return request.get(getUrl(`/users/bingUserSearch`, 1))
  },
  /**
   * 绑定用户
   */
  bindUser: function bindUser(data) {
    return _request.default.post((0, _getUrl.default)("/token/bindMySubUser", 2), data);
  },
  /**
   * 解绑用户
   */
  unbindUser: function unbindUser(subUserId) {
    return _request.default.post((0, _getUrl.default)("/users/unbindMySubUser?subUserId=".concat(subUserId), 1));
  },
  /**
   *
   * 登录绑定用户
   */
  loginBindUser: function loginBindUser(data) {
    return _request.default.post((0, _getUrl.default)("/token/loginBindUser?account=".concat(data.account, "&portalId=").concat(data.portalId, "&authSubSystem=").concat(data.authSubSystem), 2));
  },
  /**
   * 获取可以登录的门户信息
   * @param {*} bizType
   */
  getPortalInfo: function getPortalInfo(bizType, orgCode) {
    return _request.default.get((0, _getUrl.default)("/portal/getPortalListByOrgCode?bizType=".concat(bizType, "&orgCode=").concat(orgCode), 1));
  }
};
var _default = exports.default = obj;