"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var _getUrl = _interopRequireDefault(require("@/utils/get-url"));
/**
 * 消息接口
 */

var objApi = {
  /**
   * 新增
   * @param {*} data 内容
   */
  create: function create(data) {
    return _request.default.post((0, _getUrl.default)("/tpMessage/add", 8), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  updateMessage: function updateMessage(data) {
    return _request.default.post((0, _getUrl.default)("/tpMessage/edit", 8), data);
  },
  /**
   * 获取消息
   * @param {*} userId 
   */
  getMessage: function getMessage(pageNum, pageSize, userId, type, status) {
    return _request.default.get((0, _getUrl.default)("/tpMessage/get/".concat(userId, "?type=").concat(type, "&status=").concat(status, "&pageNum=").concat(pageNum, "&pageSize=").concat(pageSize), 8));
  },
  /**
   * 添加站内消息
   * @param {*} data 
   */
  addSystemMessage: function addSystemMessage(data) {
    return _request.default.post((0, _getUrl.default)("/tpMessage/add", 8), data);
  },
  /**
    * 消息通知列表
    * @param {*} pageNum 当前页
    * @param {*} pageSize 每页显示条数
    * @param {*} data 内容
    */
  getMessageList: function getMessageList(pageNum, pageSize, data) {
    return _request.default.get((0, _getUrl.default)("/message/template/list?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
    * 消息通知分类数据
    */
  getClassifyList: function getClassifyList() {
    return _request.default.get((0, _getUrl.default)("/message/templatecategory/list", 8));
  },
  /**
    * 消息通知范围数据
    */
  getRangeList: function getRangeList() {
    return _request.default.get((0, _getUrl.default)("/message/range/list", 8));
  },
  /**
   * 消息通知是否启用
   * @param {*} id 内容
   */
  isEnableMessage: function isEnableMessage(data) {
    return _request.default.post((0, _getUrl.default)("/message/template/toggle", 8), data);
  },
  /**
    * 消息通知删除
    */
  deleteRangeList: function deleteRangeList(ids) {
    return _request.default.delete((0, _getUrl.default)("/message/template/delete/".concat(ids), 8));
  },
  /**
    * 消息通知新增
    * @param {*} data 内容
    */
  addMessage: function addMessage(data) {
    return _request.default.post((0, _getUrl.default)("/message/template/add", 8), data);
  },
  /**
    * 消息通知查单个数据
    * @param {*} id
    */
  viewSingleMessage: function viewSingleMessage(id) {
    return _request.default.get((0, _getUrl.default)("/message/template/get?id=".concat(id), 8));
  },
  /**
   * 消息通知删除
   */
  deleteMessageReceivedList: function deleteMessageReceivedList(ids) {
    return _request.default.delete((0, _getUrl.default)("/message/v2/deleteReceivedMessage/".concat(ids), 8));
  },
  /**
    * 站内消息列表
    * @param {*} pageNum 当前页
    * @param {*} pageSize 每页显示条数
    * @param {*} data 内容
    */
  getMessageOnsiteList: function getMessageOnsiteList(pageNum, pageSize, data) {
    return _request.default.get((0, _getUrl.default)("/message/v2/list?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
    * 站内消息删除
    * @param {*} id 
    */
  deleteMessageOnsite: function deleteMessageOnsite(id) {
    return _request.default.delete((0, _getUrl.default)("/message/v2/delete/".concat(id), 8));
  },
  /**
    * 站内消息查单个数据
    * @param {*} id
    */
  viewMessageOnsite: function viewMessageOnsite(id) {
    return _request.default.get((0, _getUrl.default)("/message/v2/get?id=".concat(id), 8));
  },
  /**
    * 站内消息新增
    * @param {*} data 内容
    */
  addMessageOnsite: function addMessageOnsite(data) {
    return _request.default.post((0, _getUrl.default)("/message/v2/sendNewMessage", 8), data);
  },
  /**
    * 站内消息编辑
    * @param {*} data
    */
  updateMessageOnsite: function updateMessageOnsite(data) {
    return _request.default.post((0, _getUrl.default)("/message/v2/update", 8), data);
  },
  /**
    * 消息通知编辑
    * @param {*} data 内容
    */
  editMessage: function editMessage(data) {
    return _request.default.post((0, _getUrl.default)("/message/template/update", 8), data);
  },
  /**
   * 收到的消息列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getMessageReceivedList: function getMessageReceivedList(pageNum, pageSize, data) {
    return _request.default.get((0, _getUrl.default)("/message/v2/listReceivedMessage?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
    * 收到的消息删除
    * @param {*} id 
    */
  deleteMessageReceived: function deleteMessageReceived(id) {
    return _request.default.delete((0, _getUrl.default)("/message/v2/deleteReceivedMessage/".concat(id), 8));
  },
  /**
    * 收到的消息查单个数据
    * @param {*} id
    */
  viewMessageReceived: function viewMessageReceived(id) {
    return _request.default.get((0, _getUrl.default)("/message/v2/markread/".concat(id), 8));
  },
  /**
    * 公告消息列表
    * @param {*} pageNum 当前页
    * @param {*} pageSize 每页显示条数
    * @param {*} data 内容
    */
  getAnnouncementList: function getAnnouncementList(pageNum, pageSize, data) {
    return _request.default.get((0, _getUrl.default)("/message/v2/listReceivedMessage?messageBusinessGroup=NOTICE&pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
    * 公告消息新增
    * @param {*} data 内容
    */
  addAnnouncement: function addAnnouncement(data) {
    return _request.default.post((0, _getUrl.default)("/message/v2/sendNewMessage?messageBusinessGroup=NOTICE", 8), data);
  },
  /**
      * 公告消息详情
      * @param {*} id
      */
  viewAnnouncement: function viewAnnouncement(id) {
    return _request.default.get((0, _getUrl.default)("/message/v2/get?id=".concat(id), 8));
  },
  /**
    * 公告消息弹窗
    * @param {*} data
    */
  newAnnouncementMsg: function newAnnouncementMsg(data) {
    return _request.default.get((0, _getUrl.default)("/message/v2/listReceivedMessage", 8), {
      params: data
    });
  },
  /**
    * 收到的未读消息
    */
  getListReceivedNewMessage: function getListReceivedNewMessage() {
    return _request.default.get((0, _getUrl.default)("/message/v2/listReceivedNewMessage", 8));
  },
  // ******************************公告管理（新）******************************
  /**
   * 分页查询
   * @param {*} data
   */
  getNoticeList: function getNoticeList(data) {
    return _request.default.get((0, _getUrl.default)("/notice/searchList", 8), {
      params: data
    });
  },
  /**
   * 创建公告
   * @param {*} data
   */
  createNotice: function createNotice(data) {
    return _request.default.post((0, _getUrl.default)("/notice/create", 8), data);
  },
  /**
   * 修改公告
   * @param {*} noticeId
   * @param {*} data
   */
  updateNotice: function updateNotice(noticeId, data) {
    return _request.default.put((0, _getUrl.default)("/notice/update?noticeId=".concat(noticeId), 8), data);
  },
  /**
   * 批量删除公告
   * @param {*} ids
   */
  batchDeleteNotice: function batchDeleteNotice(ids) {
    return _request.default.delete((0, _getUrl.default)("/notice/batchDelete?ids=".concat(ids), 8));
  },
  /**
   * 查询一条
   * @param {*} noticeId
   */
  getNoticeInfo: function getNoticeInfo(noticeId) {
    return _request.default.get((0, _getUrl.default)("/notice/get?noticeId=".concat(noticeId), 8));
  },
  /**
   * 查询公告附件
   * @param {*} noticeId
   */
  getNoticeFileList: function getNoticeFileList(noticeId) {
    return _request.default.get((0, _getUrl.default)("/notice/getFileList?noticeId=".concat(noticeId), 8));
  },
  /**
   * 查询公告发送目标
   * @param {*} noticeId
   */
  getNoticeTargetList: function getNoticeTargetList(noticeId) {
    return _request.default.get((0, _getUrl.default)("/notice/getTargetList?noticeId=".concat(noticeId), 8));
  },
  /**
   * 创建公告用户已读
   * @param {*} data
   */
  createNoticeRead: function createNoticeRead(data) {
    return _request.default.post((0, _getUrl.default)("/notice/createRead", 8), data);
  },
  /**
   * 查询公告已读用户
   * @param {*} data
   */
  getNoticeReadList: function getNoticeReadList(data) {
    return _request.default.get((0, _getUrl.default)("/notice/getReadList", 8), {
      params: data
    });
  },
  /**
   * 发布公告
   * @param {*} noticeId
   * @param {*} published
   */
  noticePublish: function noticePublish(noticeId, published) {
    return _request.default.put((0, _getUrl.default)("/notice/publish?noticeId=".concat(noticeId, "&published=").concat(published), 8));
  },
  /**
     * 公告批量用户已读
     * @param {*} data
     */
  batchCreateRead: function batchCreateRead(data) {
    return _request.default.post((0, _getUrl.default)("/notice/batchCreateRead", 8), data);
  },
  // ******************************系统公告（新）******************************
  /**
   * 查询用户公告
   * @param {*} data
   */
  getUserNotice: function getUserNotice(data) {
    return _request.default.get((0, _getUrl.default)("/notice/getUserNotice", 8), {
      params: data
    });
  },
  /**
   * 查询工作台公告
   * @param {*} data
   */
  getUserNoticeList: function getUserNoticeList(data) {
    return _request.default.get((0, _getUrl.default)("/notice/getUserNoticeList", 8), {
      params: data
    });
  },
  /**
   * 查询系统公告
   * @param {*} data
   */
  searchUserNotice: function searchUserNotice(data) {
    return _request.default.get((0, _getUrl.default)("/notice/searchUserNotice", 8), {
      params: data
    });
  }
};
var _default = exports.default = objApi;