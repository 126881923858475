"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    PanThumb: _PanThumb.default
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          email: '',
          avatar: '',
          roles: ''
        };
      }
    }
  }
};