"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getUrl;
var _index = _interopRequireDefault(require("../config/index"));
function getUrl(url, domainType) {
  //domainType 默认为1，取apiDomain；可自定义其他域名
  var domain = process.env.VUE_APP_APIDOMAIN;
  if (domainType == 8) {
    //消息服务
    domain = _index.default.apiDomain + '/' + _index.default.msgService;
  } else if (domainType == 5) {
    //资源服务
    domain = _index.default.apiDomain + '/' + _index.default.resourceService;
  } else if (domainType == 4) {
    //一丰定制化服务
    domain = _index.default.apiDomain + '/' + _index.default.yfCustomizedService;
  } else if (domainType == 3) {
    domain = _index.default.apiDomain + '/' + _index.default.orgServer;
  } else if (domainType == 2) {
    domain = _index.default.apiDomain + '/' + _index.default.authServer;
  } else if (domainType == 1) {
    domain = _index.default.apiDomain + '/' + _index.default.coreServer;
  } else if (domainType == 8) {
    //消息服务
    domain = _index.default.apiDomain + '/' + _index.default.msgService;
  } else if (domainType == 7) {
    //存储服务
    domain = _index.default.apiDomain + '/' + _index.default.tpStorageService;
  } else if (domainType == 12) {
    //组织机构
    domain = _index.default.apiDomain + '/' + _index.default.orgServer;
  } else if (domainType == 15) {
    //呼叫
    domain = _index.default.apiDomain + '/' + _index.default.ocCoreService;
  } else if (domainType == 16) {
    //车险业务
    domain = _index.default.apiDomain + '/' + _index.default.viCoreServer;
  }
  return domain + url;
}