"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _topMenu = _interopRequireDefault(require("@/api/topMenu"));
var portalId = localStorage.getItem('portalId');
var state = {
  topMenuTree: []
};
var mutations = {
  SET_TOP_MENU_TREE: function SET_TOP_MENU_TREE(state, topMenuTree) {
    state.topMenuTree = topMenuTree;
  }
};
var actions = {
  getTopMenuTree: function getTopMenuTree(_ref) {
    var commit = _ref.commit;
    if (portalId) {
      // topMenuRequest.getTopMenuTreeByPortalId(portalId).then(res => {
      //   if(res.code == 200){
      //     commit("SET_TOP_MENU_TREE",res.data);
      //   }
      // })
    }
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};