"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
var _GithubCorner = _interopRequireDefault(require("@/components/GithubCorner"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "DashboardEditor",
  components: {
    PanThumb: _PanThumb.default,
    GithubCorner: _GithubCorner.default
  },
  data: function data() {
    return {
      emptyGif: "https://wpimg.wallstcn.com/0e03b7da-db9e-4819-ba10-9016ddfdaed3"
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["name", "avatar", "roles"]))
};