"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Download",
  data: function data() {
    return {
      openApi: process.env.VUE_APP_OPEN_API,
      height: document.body.clientHeight,
      contractFileUrl: null,
      contractNo: null
    };
  },
  created: function created() {
    this.initData();
  },
  mounted: function mounted() {
    window.addEventListener("resize", this.resize);
  },
  methods: {
    initData: function initData() {
      this.contractNo = this.$route.query.contractNo || null;
      this.getPreviewInfo();
    },
    getPreviewInfo: function getPreviewInfo() {
      var _this = this;
      (0, _axios.default)({
        method: "get",
        url: "".concat(this.openApi, "/open/selfsigncontract/getByContractNo"),
        params: {
          contractNo: this.contractNo
        }
      }).then(function (response) {
        _this.loading = false;
        if (response.data.code == 200) {
          if (response.data.data) {
            var resData = response.data.data;
            _this.contractFileUrl = resData.contractFileUrl.replace(resData.contractName, encodeURIComponent(resData.contractName));
            window.open(_this.contractFileUrl);
          }
          ;
        } else {
          _this.$message.warning(response.data.msg);
        }
        ;
      }).catch(function (error) {
        _this.loading = false;
        console.log(error, 'error');
      });
    },
    resize: function resize() {
      if (document.body.clientHeight < document.body.clientWidth) {
        this.height = document.body.clientHeight;
      } else {
        this.height = document.body.clientHeight;
      }
      ;
    }
  }
};