"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
var _pdfh = _interopRequireDefault(require("pdfh5"));
require("pdfh5/css/pdfh5.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "PdfView",
  props: {
    pdfId: {
      type: String,
      default: 'pdfId'
    },
    dochref: {
      type: String,
      default: ''
    },
    pdfName: {
      type: String,
      default: ''
    },
    refresh: {
      type: Boolean,
      default: true
    },
    pdfFileName: {
      type: String,
      default: '.pdf'
    },
    returnUrl: {
      type: String,
      default: ''
    }
  },
  watch: {
    refresh: function refresh(val) {
      if (val) {
        console.log('val :>> ', this.dochref);
        this.pdfSrc = this.dochref;
        this.pdf_height = 0;
        this.initData();
      }
    }
  },
  data: function data() {
    return {
      pdfSrc: "",
      // pdf文件页码
      currentPage: 0,
      scale: 2,
      pdfh5: null,
      pdf_height: 0,
      isAndroid: false,
      isiOS: false,
      suffix: '',
      isApp: false
    };
  },
  created: function created() {
    this.pdfSrc = "";
  },
  mounted: function mounted() {
    this.pdfSrc = "";
    this.initData();
    this.pdf_height = 0;
  },
  methods: {
    // 关闭
    goBack: function goBack() {
      this.$emit("closeViewer");
    },
    // 初始化
    initData: function initData() {
      var _this = this;
      this.suffix = _.toLower(this.pdfFileName.substring(this.pdfFileName.lastIndexOf(".") + 1));
      var u = navigator.userAgent;
      // android终端
      this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // 判断是否app打开
      this.isApp = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.userLoginInfo || window.android;
      this.$nextTick(function () {
        _this.pdfSrc = _this.dochref;
        if (_this.suffix == 'pdf') {
          console.log('this.pdfSrc :>> ', _this.dochref);
          _this.currentPage = 1;
          _this.pdfh5 = new _pdfh.default("#" + _this.pdfId, {
            pdfurl: _this.pdfSrc,
            scale: _this.scale,
            cMapUrl: 'https://unpkg.com/pdfjs-dist@2.16.105/cmaps/'
          });
          // 监听完成事件
          var self = _this;
          _this.pdfh5.on("complete", function (status, msg, time) {
            console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum);
            var pdf_height = window.document.getElementsByClassName('pdfViewer');
            self.pdf_height = pdf_height[0].scrollHeight;
          });
          _this.pdfh5.on("scroll", function (num) {});
          _this.pdfh5.on("zoom", function (scale) {
            self.scale = scale;
          });
        }
      });
    },
    // 下载
    downloadFile: function downloadFile() {
      // if (this.isApp) {
      //     this.clipboard(this.pdfSrc);
      // } else {
      //     if (this.isAndroid) {
      //         this.clipboard(this.pdfSrc);
      //         window.open(this.pdfSrc);
      //     } else if (this.isiOS) {
      //         this.clipboard(this.pdfSrc);
      //     } else {
      //         this.clipboard(this.pdfSrc);
      //         window.open(this.pdfSrc);
      //     };
      // };
      this.clipboard(this.pdfSrc);
    },
    clipboard: function clipboard(text) {
      var input = document.createElement('input');
      document.body.appendChild(input);
      input.value = text;
      input.select();
      document.execCommand('copy');
      this.$toast('复制成功，请到浏览器下载');
      input.remove();
    }
  }
};