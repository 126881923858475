"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.base64decode = base64decode;
exports.base64encode = base64encode;
exports.character = character;
exports.checkPermission = checkPermission;
exports.dataFormat = dataFormat;
exports.dateFormat = dateFormat;
exports.default = void 0;
exports.downFile = downFile;
exports.encryptRSAData = encryptRSAData;
exports.findChildByOrgCode = findChildByOrgCode;
exports.formatInsTime = formatInsTime;
exports.formatTime = formatTime;
exports.getAfterDate = getAfterDate;
exports.getAnalysisIdCard = getAnalysisIdCard;
exports.getBeforeDate = getBeforeDate;
exports.getBeforeOfDate = getBeforeOfDate;
exports.getBeforeOfMonth = getBeforeOfMonth;
exports.getCityItem = getCityItem;
exports.getCurrentDate = getCurrentDate;
exports.getCurrentOrganization = getCurrentOrganization;
exports.getDayOfMonth = getDayOfMonth;
exports.getFuelTypeCode = getFuelTypeCode;
exports.getLastDate = getLastDate;
exports.getLastMonth = getLastMonth;
exports.getLastWeekData = getLastWeekData;
exports.getLastYear = getLastYear;
exports.getNextDate = getNextDate;
exports.getNextYear = getNextYear;
exports.getNowDate = getNowDate;
exports.getOsInfo = getOsInfo;
exports.getPlateColorType = getPlateColorType;
exports.getPrevCurYear = getPrevCurYear;
exports.getProvinceItem = getProvinceItem;
exports.getSupplyers = getSupplyers;
exports.getUserOrgInfo = getUserOrgInfo;
exports.getUserOrgInfoDealerOem = getUserOrgInfoDealerOem;
exports.getVechileTypeByClass = getVechileTypeByClass;
exports.getVechileTypeByClassid = getVechileTypeByClassid;
exports.getVehicleType = getVehicleType;
exports.idCardValidate = idCardValidate;
exports.initCityTree = initCityTree;
exports.isLeapYear = isLeapYear;
exports.isTrueValidateCodeBy18IdCard = isTrueValidateCodeBy18IdCard;
exports.isValidityBrithBy15IdCard = isValidityBrithBy15IdCard;
exports.isValidityBrithBy18IdCard = isValidityBrithBy18IdCard;
exports.limitInput = limitInput;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
exports.saveFile = saveFile;
var _typeof2 = _interopRequireDefault(require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.24.7@@babel/runtime/helpers/typeof.js"));
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.number.to-fixed.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.string.pad-start.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url.js");
require("core-js/modules/web.url.to-json.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _jsencrypt = _interopRequireDefault(require("jsencrypt"));
/**
 * @desc 扩展对象继承
 * @param  {Object} out 一个或多个对象
 * @return {Object} 对象
 */
Object.extend = function (out) {
  out = out || {};
  for (var i = 1; i < arguments.length; i++) {
    if (!arguments[i]) continue;
    for (var key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key)) out[key] = arguments[i][key];
    }
  }
  return out;
};
var _default = exports.default = {
  /**
   * @desc  判断对象是否为空
   * @param  {Object} o 对象
   * @return {Boolean}
   */
  isEmptyObject: function isEmptyObject() {
    var o = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var flag = true;
    for (var k in o) {
      if (k) {
        flag = false;
        break;
      }
    }
    return flag;
  },
  /**
   * @description 去除前后空格
   * @param  {String} 值
   * @return {String}
   */
  trim: function trim(val) {
    return val.replace(/(^\s*)|(\s*$)/g, '');
  },
  /**
   * @desc  获取 localStorage 中指定的变量
   * @param  {String} name 名称
   * @return {*} rs
   */
  getStorage: function getStorage(name) {
    return this._string2json(window.localStorage[name]);
  },
  /**
   * @desc  设置或添加 localStorage 中指定的变量
   * @param {String} name 名称
   */
  setStorage: function setStorage(name, value) {
    window.localStorage[name] = this._json2string(value);
  },
  /**
   * @desc  删除 localStorage 中指定的变量
   * @param  {String} name 名称
   */
  delStorage: function delStorage(name) {
    window.localStorage.removeItem(name);
  },
  /**
   * @desc json转string
   * @param  {*} value 值
   * @return {*} value 值
   */
  _json2string: function _json2string(value) {
    return JSON.stringify(value);
  },
  /**
   * @desc  string转json
   * @param  {*} value 值
   * @return {*} value 值
   */
  _string2json: function _string2json(value) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      //
    }
    return value;
  },
  // 保留小数位，替代Number.toFixed()方法，针对于某些数据（16.455）不能做到四舍五入
  toFixed: function toFixed(value) {
    var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var pos = value.toString().indexOf('.');
    var decimalPlaces = value.toString().length - pos - 1;
    var intValue = value * Math.pow(10, decimalPlaces);
    var divisor1 = Math.pow(10, decimalPlaces - num);
    var divisor2 = Math.pow(10, num);
    return Math.round(intValue / divisor1) / divisor2;
  },
  /**
   * 如果是小数则保留小数位，默认两位
   * @param  {[type]} value [description]
   * @param  {Number} num   [description]
   * @return {[type]}       [description]
   */
  toFloatFixed: function toFloatFixed(value) {
    var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    var values = Number(value);
    if (values) {
      if (/^\d+\.\d+/.test(values)) {
        return this.toFixed(values, num);
      } else {
        return this.toFixed(values);
      }
    }
    return value;
  }
};
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * 保存文件
 * @param {} res
 * @param {} fileName
 */
function saveFile(resData, contentType, fileName) {
  if (!resData) return false;
  try {
    var blob = new Blob([resData], {
      type: contentType
    });
    var downloadElement = document.createElement('a');
    var href = URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = fileName;
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    URL.revokeObjectURL(href); //释放掉blob对象
    return true;
  } catch (err) {
    console.log('Download=>error:', err);
    return false;
  }
  return true;
}
/**
 * 格式化日期
 * @param {*} time
 */
function dataFormat(time) {
  return "".concat(time.getFullYear(), "-").concat(time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1), "-").concat(time.getDate() >= 10 ? time.getDate() : '0' + time.getDate(), " ").concat(time.getHours() >= 10 ? time.getHours() : '0' + time.getHours(), ":").concat(time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes(), ":").concat(time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds());
}
/**
 * 格式化日期
 * @param {*} fmt "YYYY-mm-dd HH:MM"
 * @param {*} date 日期
 */
function dateFormat(fmt, date) {
  var ret;
  var _date = new Date(date);
  var opt = {
    "Y+": _date.getFullYear().toString(),
    // 年
    "m+": (_date.getMonth() + 1).toString(),
    // 月
    "d+": _date.getDate().toString(),
    // 日
    "H+": _date.getHours().toString(),
    // 时
    "M+": _date.getMinutes().toString(),
    // 分
    "S+": _date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (var k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
    }
    ;
  }
  ;
  return fmt;
}
/**
 * 格式化保险投保起止日期
 * @param {} time
 */
function formatInsTime(time) {
  if (!time) return;
  var newTime = '';
  var timeYear = time.split(' ')[0];
  var timeMinute = time.split(' ')[1];
  var newtimeMinute = '24:00:00';
  if (timeMinute == '23:59:59') {
    newTime = timeYear + ' ' + newtimeMinute;
  } else {
    newTime = timeYear + ' ' + timeMinute;
  }
  newTime = newTime.slice(0, 16);
  return newTime;
}
function getCurrentDate() {
  //获取当前日期
  var myDate = new Date();
  var nowY = myDate.getFullYear();
  var nowM = myDate.getMonth() + 1;
  var nowD = myDate.getDate();
  var date = nowY + "-" + (nowM < 10 ? "0" + nowM : nowM) + "-" + (nowD < 10 ? "0" + nowD : nowD); //当前日期
  return date;
}
function getNextDate(date, day) {
  //获取指定日期的前几天，后几天
  var dd = new Date(date);
  dd.setDate(dd.getDate() + day);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
  var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
  return y + "-" + m + "-" + d;
}
;
function getBeforeDate(day) {
  //获取指定天数前的日期
  var myDate = new Date();
  var lw = new Date(myDate.getTime() - 1000 * 60 * 60 * 24 * day);
  var lastY = lw.getFullYear();
  var lastM = lw.getMonth() + 1;
  var lastD = lw.getDate();
  var date = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD); //day之前日期
  return date;
}
function getAfterDate(day) {
  //获取指定天数后的日期
  var myDate = new Date();
  var lw = new Date(myDate.getTime() + 1000 * 60 * 60 * 24 * day);
  var lastY = lw.getFullYear();
  var lastM = lw.getMonth() + 1;
  var lastD = lw.getDate();
  var date = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD); //day之后日期
  return date;
}
function getLastWeekData() {
  //得到上周周一~周日的年月日
  var lastweek = [];
  var date = new Date();
  // 上周一的日期
  date.setDate(date.getDate() - 7 - date.getDay() + 1);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var start_day = date.getFullYear() + "-" + (month < 10 ? '0' + month : month) + "-" + (day < 10 ? '0' + day : day);
  lastweek.push(start_day);
  // 上周日的日期
  date.setDate(date.getDate() + 6);
  month = date.getMonth() + 1;
  day = date.getDate();
  var end_day = date.getFullYear() + "-" + (month < 10 ? '0' + month : month) + "-" + (day < 10 ? '0' + day : day);
  lastweek.push(end_day);
  return lastweek;
}
//获取上个月的第一天和最后一天
function getLastMonth() {
  var arr = [];
  var nowdays = new Date();
  var year = nowdays.getFullYear();
  var month = nowdays.getMonth();
  if (month == 0) {
    month = 12;
    year = year - 1;
  }
  if (month < 10) {
    month = '0' + month;
  }
  var myDate = new Date(year, month, 0);
  var startDate = year + '-' + month + '-01'; //上个月第一天
  var endDate = year + '-' + month + '-' + myDate.getDate(); //上个月最后一天
  arr.push(startDate);
  arr.push(endDate);
  return arr;
}

//获取上个月的第一天和最后一天
function getLastDate(day) {
  var arr = [];
  var nowdays = day ? new Date(day) : new Date();
  var year = nowdays.getFullYear();
  var month = nowdays.getMonth();
  if (month == 0) {
    month = 12;
    year = year - 1;
  }
  if (month < 10) {
    month = '0' + month;
  }
  var startDate = year + '-' + month + '-01'; //上个月第一天
  var endDate = year + '-' + month + '-' + getDayOfMonth(month); //上个月最后一天
  arr.push(startDate);
  arr.push(endDate);
  return arr;
}
//获取当前月的第一天和最后一天
function getNowDate(day) {
  var arr = [];
  var nowdays = day ? new Date(day) : new Date();
  var year = nowdays.getFullYear();
  var month = nowdays.getMonth() + 1;
  var d = getDayOfMonth(month);
  if (month == 0) {
    month = 12;
    year = year - 1;
  }
  if (month == 2) {
    //当前日期为闰年的2/29时，则默认为3/1
    var isCurLeap = isLeapYear(year); //当前年份是否为闰年
    d = isCurLeap ? 29 : 28;
  }
  if (month < 10) {
    month = '0' + month;
  }
  var startDate = year + '-' + month + '-01 00:00:00'; //上个月第一天
  var endDate = year + '-' + month + '-' + (d < 10 ? '0' + d : d); //上个月最后一天
  arr.push(startDate);
  arr.push(endDate);
  return arr;
}

//获取上一年的第一天和最后一天
function getLastYear() {
  var arr = [];
  var nowdays = new Date();
  var year = nowdays.getFullYear() - 1;
  var beginTime = year + '-01-01';
  var endTime = year + '-12-31';
  arr.push(beginTime);
  arr.push(endTime);
  return arr;
}
//获取上一年的第一天和今年最后一天
function getPrevCurYear() {
  var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var currentYear = '';
  var nowdays = new Date();
  var year = nowdays.getFullYear() - num;
  if (num) {
    currentYear = year + '-01-01';
  } else {
    currentYear = year + '-12-31';
  }
  return currentYear;
}
/**
 * 获取生日，性别，年龄
 * @param {*} card
 * @param {*} num
 */
function getAnalysisIdCard(card, num) {
  if (num == 1) {
    //获取出生日期
    var birth = card.substring(6, 10) + "-" + card.substring(10, 12) + "-" + card.substring(12, 14);
    return birth;
  }
  if (num == 2) {
    //获取性别
    if (parseInt(card.substr(16, 1)) % 2 == 1) {
      //男
      return "M";
    } else {
      //女
      return "F";
    }
  }
  if (num == 3) {
    //获取年龄
    var myDate = new Date();
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var age = myDate.getFullYear() - card.substring(6, 10) - 1;
    if (card.substring(10, 12) < month || card.substring(10, 12) == month && card.substring(12, 14) <= day) {
      age++;
    }
    return age;
  }
}

/**
 * 通过城市code获取对应的城市item
 * @param {*} code
 */
function getCityItem(code) {
  if (!code) return {};
  var ctiy = JSON.parse(localStorage.getItem('VI_CityPcTree')) || [];
  var cityItem = {};
  ctiy.forEach(function (item) {
    if (item.children && item.children.length > 0) {
      item.children.forEach(function (eitem) {
        if (eitem.value == code) {
          cityItem = eitem;
        }
      });
    }
  });
  return cityItem;
}
function isLeapYear(year) {
  //判断是否为闰年
  if (year % 400 === 0 || year % 4 === 0 && year % 100 !== 0) {
    return true;
  }
  return false;
}

/**
 * 根据月份获取对应的天数
 * @param {*} month
 * @returns
 */
function getDayOfMonth(month) {
  var day = 31; //默认31天
  switch (month) {
    case 4:
    case '04':
    case 6:
    case '06':
    case 9:
    case '09':
    case '11':
      day = 30;
      break;
  }
  return day;
}
/**
 * 计算下一年{add}天后的日期
 * @param {*} startTime
 * @param {*} add
 * @returns
 */
function getNextYear(startTime) {
  var add = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var curDay = new Date(startTime);
  // 下一年
  var y = curDay.getFullYear() + 1;
  var m = curDay.getMonth() + 1;
  var d = curDay.getDate();
  var currentDay = getDayOfMonth(m);
  d = parseInt(d) + add;
  if (m == 12) {
    //如果是12月份的最后一天，则年份加1，月份和天数默认1；
    y = d > 31 ? y + 1 : y;
    m = d > 31 ? 1 : m;
    d = d > 31 ? 1 : d;
  } else if (m == 2) {
    //当前日期为闰年的2/29时，则默认为3/1
    var isCurLeap = isLeapYear(curDay.getFullYear()); //当前年份是否为闰年
    var isNextLeap = isLeapYear(y); //下一年年份是否为闰年
    if (d > 28) {
      if (isCurLeap) {
        m = 3;
        d = 1;
      } else {
        m = isNextLeap ? m : 3;
        d = isNextLeap ? d : 1;
      }
    }
  } else {
    //非2，12月的其他月份，如果月份大于30或31则月份加1和天数默认1；
    m = d > currentDay ? m + 1 : m;
    d = d > currentDay ? 1 : d;
  }
  return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
}

/**
 * 获取供应商列表
 */
function getSupplyers() {
  var localData = JSON.parse(localStorage.getItem('VI_PROP_SUPPLYER')) || [];
  var supplyers = [];
  localData.forEach(function (item) {
    if (item.code) {
      supplyers.push(item);
    }
  });
  return supplyers;
}

/**
 * 获取当前登录用户所在的组织机构
 */
function getUserOrgInfo() {
  var userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
  var _orgInfo = userInfo && userInfo.organization;
  if (!_orgInfo && userInfo && userInfo.data) {
    _orgInfo = userInfo.data.organizationVO || userInfo.data.organization;
    if (_orgInfo) {
      _orgInfo.orgName = _orgInfo.fullName;
    }
  }
  return _orgInfo || {};
}

/**
 * 获取当前登录用户所在的组织机构信息(经销商-主机厂)
 */
function getUserOrgInfoDealerOem(bizType) {
  var userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
  var userInfoObj = {};
  var userInfoData = userInfo.data || userInfo;
  if (bizType === "DEALER") {
    userInfoObj.salesOrgCode = userInfoData.orgCode;
    userInfoObj.salesOrgName = userInfoData.orgName;
    userInfoObj.salesOrgId = userInfoData.orgId;
    userInfoObj.bizType = userInfo.bizType || userInfo.orgType;
    userInfoObj.salesOrgType = userInfo.bizType || userInfo.orgType;
  } else if (bizType === "OEM") {
    userInfoObj.salesOrgCode = userInfoData.organization ? userInfoData.organization.oemCode : userInfoData.oemCode;
    userInfoObj.salesOrgName = userInfoData.organization ? userInfoData.organization.oemName : userInfoData.oemName;
    userInfoObj.salesOrgId = userInfoData.orgId;
    userInfoObj.bizType = userInfo.bizType || userInfo.orgType;
    userInfoObj.salesOrgType = userInfo.bizType || userInfo.orgType;
  }
  return userInfoObj;
}
/**
 * 获取当前登录用户所在的组织机构列表
 */
function findChildByOrgCode(item, _orgCode) {
  if (_.isEmpty(item.children)) return null;
  var child = _.find(item.children, function (eitem) {
    return eitem.orgCode == _orgCode;
  });
  if (child) {
    return child;
  } else {
    item.children.some(function (eitem) {
      var _temp = findChildByOrgCode(eitem, _orgCode);
      if (_temp) {
        child = _temp;
        return true;
      }
    });
  }
  return child;
}

/**
 * 获取当前登录用户所在的组织机构列表
 */
function getCurrentOrganization(organizationInfo) {
  var organizations = {};
  if (!_.isEmpty(organizationInfo)) {
    var _orgInfo = getUserOrgInfo();
    var orgCode = _orgInfo.orgCode || '';
    // orgCode = '.1.3.721.11.'
    if (!orgCode) {
      return organizationInfo[0];
    }
    organizationInfo.forEach(function (item) {
      if (_.toString(item.orgCode) == _.toString(orgCode)) {
        organizations = item;
      } else {
        organizations = findChildByOrgCode(item, orgCode);
      }
    });
  }
  return organizations || {};
}

/**
 * 获取指定日期之前的天数
 */
function getBeforeOfDate(time, day) {
  //获取指定天数前的日期
  var myDate = new Date(time);
  var lw = new Date(myDate.getTime() - 1000 * 60 * 60 * 24 * day);
  var lastY = lw.getFullYear();
  var lastM = lw.getMonth() + 1;
  var lastD = lw.getDate();
  var date = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD); //day之前日期
  return date;
}

/**
 * 通过省code获取对应的省item
 * @param {*} code
 */
function getProvinceItem(code) {
  if (!code) return {};
  var province = JSON.parse(localStorage.getItem('VI_CityPcTree')) || [];
  var provinceItem = _.find(province, function (eitem) {
    return eitem.value == code;
  });
  return provinceItem;
}
/**
 * 通过号牌种类匹配号牌颜色
 * @param {*} code
 */
function getPlateColorType(code) {
  var color = '';
  switch (code) {
    case '01':
    case '16':
      color = '04';
      break;
    case '02':
      color = '01';
      break;
    case '03':
    case '04':
    case '05':
    case '06':
      color = '02';
      break;
    case '18':
    case '20':
    case '23':
    case '31':
    case '32':
      color = '03';
      break;
    case '51':
      color = '07';
      break;
    case '52':
      color = '06';
      break;
    default:
      break;
  }
  return color;
}
/**
 * 通过车型品牌型号获取号牌种类
 * @param {*} code
 */
function getVehicleType(code) {
  var typeCode = 'K33';
  switch (code) {
    case '哈弗':
    case 'WEY':
      typeCode = 'K31';
      break;
    case '皮卡':
      typeCode = 'H31';
      break;
    case '欧拉':
    case '腾翼':
      typeCode = 'K33';
      break;
    default:
      break;
  }
  return typeCode;
}
/**
 *@desc 只能输入字母和数字
 *@param { String } value
 */
function character(value) {
  if (value) {
    value = value.replace(/[^A-Za-z0-9]/g, '');
  }
  return value;
}

/**
 * 能源类型匹配
 * @param {*} code
 * @param {*} isBj
 */
function getFuelTypeCode(code, isBj) {
  if (!code) return '';
  var ENERGYTYPECONVERSION = {
    "D1": "0",
    "D2": "0",
    "D3": "0",
    "D4": "0",
    "D5": "4",
    "D6": "1",
    "D7": "0",
    "D8": "2",
    "D9": "0",
    "D11": "0",
    "D12": "3",
    "D13": "0",
    "D14": "0",
    "D15": "0"
  };
  var BJ_ENERGYTYPECONVERSION = {
    "D1": "A",
    "D2": "B",
    "D3": "E",
    "D4": "F",
    "D5": "O",
    "D6": "C",
    "D7": "O",
    "D8": "C",
    "D9": "L",
    "D11": "Z",
    "D12": "O",
    "D13": "D",
    "D14": "N",
    "D15": "Y"
  };
  return isBj ? BJ_ENERGYTYPECONVERSION[code] : ENERGYTYPECONVERSION[code];
}
/**
 * 车辆行驶证类型匹配
 * @param {*} classid
 */
function getVechileTypeByClassid(classid) {
  if (!classid) return;
  //匹配车辆类型
  var jqxClasses = JSON.parse(localStorage.getItem('VI_JQX_CLASS')) || [];
  var jqxClass = _.find(jqxClasses, function (item) {
    return item.dictName == classid;
  });
  if (!jqxClass) return;
  var VEHTYPETOOWNERVEHTYPEMAP = {
    "A012": "K33",
    "A022": "K31",
    "A032": "K21",
    "A042": "K11",
    "A052": "K11",
    "B012": "H31",
    "B022": "H21",
    "B032": "H21",
    "B042": "H11",
    "C022": "H14",
    "B052": "H51",
    "B062": "N11",
    "B142": "Q11",
    "C032": "Z11",
    "C042": "Z11",
    "C112": "G31",
    "C122": "G21",
    "C132": "G21",
    "C142": "G11",
    "C152": "G13",
    "C162": "G13",
    "C172": "G12",
    "C182": "B17",
    "B101": "H51",
    "B102": "H51",
    "B103": "H51",
    "B104": "H51",
    "B105": "H51",
    "D112": "M22",
    "D012": "M11",
    "D022": "M11",
    "E012": "T11",
    "E022": "T11",
    "E112": "T11",
    "E122": "T11",
    "E201": "T11",
    "E202": "T11",
    "E203": "T11",
    "E204": "T11",
    "E205": "T11",
    "E301": "T11",
    "E302": "T11",
    "E303": "T11",
    "E304": "T11",
    "E305": "T11",
    "B201": "N11",
    "B202": "N11",
    "B203": "N11",
    "B204": "N11",
    "B205": "N11"
  };
  return VEHTYPETOOWNERVEHTYPEMAP[jqxClass.dictCode];
}

/**
 * 根据车型查询返回的“车辆种类”、“商业险字典”对应带出“行驶证车辆类型”
 * @param {*} classid
 * @param {*} className
 */
function getVechileTypeByClass(classId, className) {
  if (!classId) return;
  if (!className) {
    return getVechileTypeByClassid(classId);
  }
  //匹配车辆类型
  var typeCode = '';
  switch (className) {
    case '轿车类':
      switch (classId) {
        case 'KA':
          typeCode = 'K33';
          break;
      }
      break;
    case '旅行车类':
      switch (classId) {
        case 'KB':
          typeCode = 'K31';
          break;
        case 'KC':
          typeCode = 'K21';
          break;
      }
      break;
    case '越野车类':
      switch (classId) {
        case 'KA':
        case 'KB':
          typeCode = 'K31';
          break;
        case 'KC':
          typeCode = 'K21';
          break;
      }
      break;
    case '套牌车类':
      switch (classId) {
        case 'KA':
          typeCode = 'K31';
          break;
      }
      break;
    case '大型客车类':
      switch (classId) {
        case 'KD':
        case 'KE':
          typeCode = 'K11';
          break;
      }
      break;
    case '轻型货车类':
      switch (classId) {
        case 'HA':
          typeCode = 'H41';
          break;
        case 'HB':
          typeCode = 'H31';
          break;
      }
      break;
    case '货车类':
      switch (classId) {
        case 'HC':
          typeCode = 'H21';
          break;
        case 'HD':
          typeCode = 'H11';
          break;
      }
      break;
    case '农用车类':
      switch (classId) {
        case 'NA':
          typeCode = 'H51';
          break;
      }
      break;
    default:
      break;
  }
  return typeCode;
}

/**
 * 限制精度
 * @param {*} value
 * @param {*} limit
 * @param {*} maxVlue
 */
function limitInput(value) {
  var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  if (!value) return '';
  var str = value.toString();
  var len1 = str.substr(0, 1);
  var len2 = str.substr(1, 1);
  //如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 == 0 && len2 != ".") {
    str = str.substr(1, 1);
  }
  //第一位不能是.
  if (len1 == ".") {
    str = "";
  }
  //限制只能输入一个小数点
  if (str.indexOf(".") != -1) {
    var str_ = str.substr(str.indexOf(".") + 1);
    if (str_.indexOf(".") != -1) {
      str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
    }
  }
  //正则替换
  str = str.replace(/[^\d^\.]+/g, ''); // 保留数字和小数点
  var temp = str.split('.');
  //保留指定小数位
  if (temp.length > 1 && temp[1].length > limit && limit > 0) {
    temp[1] = temp[1].substring(0, limit);
    str = _.join([temp[0], temp[1]], '.');
  } else {
    if (limit == 0) {
      str = temp[0];
    }
  }
  return str;
}

/**
 * 获取指定月数之前的日期
 */
function getBeforeOfMonth(date, monthNum) {
  var dateArr = date.split('-');
  var year = dateArr[0]; //获取当前日期的年份
  var month = dateArr[1]; //获取当前日期的月份
  var day = dateArr[2]; //获取当前日期的日
  var days = new Date(year, month, 0);
  days = days.getDate(); //获取当前日期中月的天数
  var year2 = year;
  var month2 = parseInt(month) - monthNum;
  if (month2 <= 0) {
    year2 = parseInt(year2) - parseInt(month2 / 12 == 0 ? 1 : Math.abs(parseInt(month2 / 12)) + 1);
    month2 = 12 - Math.abs(month2) % 12;
  }
  var day2 = day;
  var days2 = new Date(year2, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }
  var t2 = year2 + '-' + month2 + '-' + day2;
  return t2;
}

// 获取操作系统信息
function getOsInfo() {
  var userAgent = navigator.userAgent.toLowerCase();
  var name = 'Unknown';
  var version = 'Unknown';
  if (userAgent.indexOf('win') > -1) {
    name = 'Windows';
    if (userAgent.indexOf('windows nt 5.0') > -1) {
      version = 'Windows 2000';
    } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
      version = 'Windows XP';
    } else if (userAgent.indexOf('windows nt 6.0') > -1) {
      version = 'Windows Vista';
    } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
      version = 'Windows 7';
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
      version = 'Windows 8';
    } else if (userAgent.indexOf('windows nt 6.3') > -1) {
      version = 'Windows 8.1';
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
      version = 'Windows 10';
    } else {
      version = 'Unknown';
    }
  } else if (userAgent.indexOf('iphone') > -1) {
    name = 'Iphone';
  } else if (userAgent.indexOf('mac') > -1) {
    name = 'Mac';
  } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
    name = 'Unix';
  } else if (userAgent.indexOf('linux') > -1) {
    if (userAgent.indexOf('android') > -1) {
      name = 'Android';
    } else {
      name = 'Linux';
    }
  } else {
    name = 'Unknown';
  }
  return {
    name: name,
    version: version
  };
}
var Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1]; // 加权因子
var ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]; // 身份证验证位值.10代表X
/**
 * 验证18位数身份证号码
 * @param idCard 18位书身份证字符串
 * @return
 */
function idCardValidate(idCard) {
  if (idCard.length == 18) {
    var a_idCard = idCard.split(""); // 得到身份证数组
    if (isValidityBrithBy18IdCard(idCard) && isTrueValidateCodeBy18IdCard(a_idCard)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
/**
 * 验证18位数身份证号码中的生日是否是有效生日
 * @param idCard 18位书身份证字符串
 * @return
 */
function isValidityBrithBy18IdCard(idCard18) {
  var year = idCard18.substring(6, 10);
  var month = idCard18.substring(10, 12);
  var day = idCard18.substring(12, 14);
  var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
  // 这里用getFullYear()获取年份，避免千年虫问题
  if (temp_date.getFullYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
    return false;
  } else {
    return true;
  }
}
/**
 * 验证15位数身份证号码中的生日是否是有效生日
 * @param idCard15 15位书身份证字符串
 * @return
 */
function isValidityBrithBy15IdCard(idCard15) {
  var year = idCard15.substring(6, 8);
  var month = idCard15.substring(8, 10);
  var day = idCard15.substring(10, 12);
  var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
  // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
  if (temp_date.getYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
    return false;
  } else {
    return true;
  }
}
/**
 * 判断身份证号码为18位时最后的验证位是否正确
 * @param a_idCard 身份证号码数组
 * @return
 */
function isTrueValidateCodeBy18IdCard(a_idCard) {
  var sum = 0; // 声明加权求和变量
  if (a_idCard[17].toLowerCase() == 'x') {
    a_idCard[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
  }
  for (var i = 0; i < 17; i++) {
    sum += Wi[i] * a_idCard[i]; // 加权求和
  }
  var valCodePosition = sum % 11; // 得到验证码所位置
  if (a_idCard[17] == ValideCode[valCodePosition]) {
    return true;
  } else {
    return false;
  }
}
/**
 * 缓存省市区列表
 * @param {} data 
 */
function initCityTree(data) {
  var VI_CityAreaTree = [];
  var VI_CityPcTree = [];
  data.forEach(function (province) {
    //判断是否有城市
    if (!_.isEmpty(province.child)) {
      var _province = {
        label: province.name,
        value: province.code,
        children: []
      };
      var _children = [];
      var _provinces = {
        label: province.name,
        value: province.code,
        children: []
      };
      var _cityChildren = [];
      province.child.forEach(function (city) {
        var _city = {
          label: city.name,
          value: city.code,
          children: []
        };
        _cityChildren.push({
          label: city.name,
          value: city.code,
          children: null
        });
        var _childrens = [];
        if (!_.isEmpty(city.child)) {
          city.child.forEach(function (area) {
            if (area) {
              _childrens.push({
                label: area.name,
                value: area.code,
                children: null
              });
            }
          });
          _children.push(_city);
        }
        _city.children = _childrens;
      });
      _provinces.children = _cityChildren;
      if (!_.isEmpty(_provinces.children)) {
        VI_CityPcTree.push(_provinces);
      }
      _province.children = _children;
      if (!_.isEmpty(_province.children)) {
        VI_CityAreaTree.push(_province);
      }
    }
  });
  //省市
  if (!_.isEmpty(VI_CityPcTree)) localStorage.setItem('VI_CityPcTree', JSON.stringify(VI_CityPcTree));
  //省市区
  if (!_.isEmpty(VI_CityAreaTree)) localStorage.setItem('VI_CityAreaTree', JSON.stringify(VI_CityAreaTree));
}
var base64EncodeChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
var base64DecodeChars = new Array(-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1, -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1);
/**
 * base64编码
 * @param {Object} str
 */
function base64encode(str) {
  var out, i, len;
  var c1, c2, c3;
  len = str.length;
  i = 0;
  out = "";
  while (i < len) {
    c1 = str.charCodeAt(i++) & 0xff;
    if (i == len) {
      out += base64EncodeChars.charAt(c1 >> 2);
      out += base64EncodeChars.charAt((c1 & 0x3) << 4);
      out += "==";
      break;
    }
    c2 = str.charCodeAt(i++);
    if (i == len) {
      out += base64EncodeChars.charAt(c1 >> 2);
      out += base64EncodeChars.charAt((c1 & 0x3) << 4 | (c2 & 0xF0) >> 4);
      out += base64EncodeChars.charAt((c2 & 0xF) << 2);
      out += "=";
      break;
    }
    c3 = str.charCodeAt(i++);
    out += base64EncodeChars.charAt(c1 >> 2);
    out += base64EncodeChars.charAt((c1 & 0x3) << 4 | (c2 & 0xF0) >> 4);
    out += base64EncodeChars.charAt((c2 & 0xF) << 2 | (c3 & 0xC0) >> 6);
    out += base64EncodeChars.charAt(c3 & 0x3F);
  }
  return out;
}
/**
 * base64解码
 * @param {Object} str
 */
function base64decode(str) {
  var c1, c2, c3, c4;
  var i, len, out;
  len = str.length;
  i = 0;
  out = "";
  while (i < len) {
    /* c1 */
    do {
      c1 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
    } while (i < len && c1 == -1);
    if (c1 == -1) break;
    /* c2 */
    do {
      c2 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
    } while (i < len && c2 == -1);
    if (c2 == -1) break;
    out += String.fromCharCode(c1 << 2 | (c2 & 0x30) >> 4);
    /* c3 */
    do {
      c3 = str.charCodeAt(i++) & 0xff;
      if (c3 == 61) return out;
      c3 = base64DecodeChars[c3];
    } while (i < len && c3 == -1);
    if (c3 == -1) break;
    out += String.fromCharCode((c2 & 0XF) << 4 | (c3 & 0x3C) >> 2);
    /* c4 */
    do {
      c4 = str.charCodeAt(i++) & 0xff;
      if (c4 == 61) return out;
      c4 = base64DecodeChars[c4];
    } while (i < len && c4 == -1);
    if (c4 == -1) break;
    out += String.fromCharCode((c3 & 0x03) << 6 | c4);
  }
  return out;
}
function downFile(url, fileName) {
  var x = new XMLHttpRequest();
  x.open('GET', url, true);
  x.responseType = 'blob';
  var isPdf = false;
  if (url.indexOf('.pdf') != -1 || url.indexOf('.PDF') != -1) {
    isPdf = true;
  }
  x.onload = function () {
    var url = isPdf ? window.URL.createObjectURL(new Blob([x.response], {
      type: 'application/pdf'
    })) : window.URL.createObjectURL(x.response);
    var a = document.createElement('a');
    a.href = url;
    if (isPdf) {
      a.target = "_blank";
    } else {
      a.download = fileName;
    }
    a.click();
  };
  x.send();
}

// 检查权限
function checkPermission(val) {
  var roles = JSON.parse(sessionStorage.getItem('roles'));
  var hasPermission = false;
  if (!_.isEmpty(roles)) {
    hasPermission = roles.some(function (role) {
      return val.includes(role);
    });
  }
  return hasPermission;
}
// RSA加密
function encryptRSAData(data) {
  var encryptedData = data;
  if (process.env.VUE_APP_PUBLIC_KEY) {
    var encrypt = new _jsencrypt.default();
    encrypt.setPublicKey(process.env.VUE_APP_PUBLIC_KEY);
    var dataToEncrypt = data;
    var encrypted = encrypt.encrypt(dataToEncrypt);
    encryptedData = encrypted;
  }
  return encryptedData || data;
}